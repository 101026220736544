// React
import React from "react";
// Material UI
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// Feather
import { ChevronLeft } from "react-feather";
// React Router
import { Link } from "react-router-dom";

function BackChevron(props) {

  return (
    <BackLink to={props.href}>
      <StyledChevronLeft />
    </BackLink>
  );

  /* History needs to be accomplished using react router v6's use navigate
  return(
    <Box onClick={() => { history.go() }} sx={{
      cursor: 'pointer',
    }}>
      <StyledChevronLeft/>
    </Box>
  );
  */
};

const StyledChevronLeft = styled(ChevronLeft)({
  color: '#153b2c',
});

const BackLink = styled(Link)({
  position: 'relative',
  display: 'block',
  top: '20px',
  left: '20px',
  width: 'calc(100% - 20px)',
  paddingBottom: '15px',
});

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case "invalid username":
      setError((prevErr) => ({ ...prevErr, email: "Invalid email address." }));
      break;
    case "invalid username/password":
    case "401":
      setError((err) => ({ ...err, password: "Incorrect password." }));
      break;
    case "name already in use":
    case "409":
      setError((err) => ({
        ...err,
        email: "Email is already registered."
      }));
      break;
    case "password must be between 6 and 128 characters":
    case "400":
      setError((err) => ({
        ...err,
        password: "Password must be between 6 and 128 characters.",
      }));
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}

function ShuffleForm(props) {
  /*
    Props:
    - fields: [{field: x, setter: y, name: z}, ...] React state and setters
    - submitF: Function to call for submitting the form
    - submitText: Text for the submit button
  */
  const generate_fields = (fields) => {
    const text_fields = fields.map(x => {
      return (
        <Box>
          <TextField
            variant="standard"
            label={x.name}
            defaultValue={x.field}
            onChange={(e) => { x.setter(e.target.value) }}
            sx={{
              color: 'black',
              marginBottom: '10px',
              marginLeft: '20px',
              marginRight: '20px',
              width: '80%',
              maxWidth: '400px',
            }}
          />
        </Box>
      );
    })

    return text_fields
  };

  return (
    <Box sx={{
      textAlign: 'center',
      width: '100%',
    }}
    >
      {generate_fields(props.fields)}
      <Button variant='contained' onClick={props.submitF}
        sx={{
          borderRadius: '25px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '25px',
          textTransform: 'unset',
          width: '80%',
          maxWidth: '300px',
        }}
      >
        {props.submitText}
      </Button>
    </Box>
  );
}

function imageError() {
  if (this.src !== 'images/image_error.jpg') this.src = 'images/image_error.jpg';
}

export { BackChevron, 
         handleAuthenticationError, 
         parseAuthenticationError, 
         ShuffleForm, 
         imageError };