// Shuffle Components
import { Heading, SavedListingFeed } from '../components/Feed';
import { useRealmApp } from '../RealmApp';
// Material UI
import { Container } from "@mui/material";
// GraphQL Queries
import { SavedListingsQuery } from '../graphql/ListingMutations';

export default function SavedListings() {
  const app = useRealmApp();

  const user_id = app.currentUser.customData._id;
  const mylisting_query_args = { input: user_id};

  return(
    <Container disableGutters sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: 'calc(100vh - 88px)',
      paddingBottom: 2,
      backgroundColor: '#fbfbfb',
    }}>
      <Heading>Saved Listings</Heading>
      <SavedListingFeed query={SavedListingsQuery} query_args={mylisting_query_args}/>
    </Container>
  );
}