// React
import React, { useState } from 'react';
// Mongo Realm
import { useRealmApp } from "../RealmApp";
// React Router
import { Link, useParams } from "react-router-dom";
// Material UI
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
// Apollo & GraphQL
import { useQuery } from '@apollo/client';
import { ViewListing } from '../graphql/ListingMutations';
// Shuffle
import ImageGallery from './ImageGallery';
import { ListingChip } from './Feed';
//Feather
import { MapPin, X } from 'react-feather';

export default function Listing(props) {

  let params = useParams();
  let listing_id = params.listingID;

  const { loading, error, data } = useQuery(
    ViewListing,
    {
      variables: { input: listing_id },
    }
  );
  let x = <h1>Hello?</h1>
  if (loading) x = <h1>Loading</h1>
  if (error) x = <h1>{error.message}</h1>
  if (data) {
    x = <ListingView listingData={data} />
  }
  return (
    <Box>
      {x}
    </Box>
  );
}

/*
  Checks to see if the user likes the listing by checking the listings "liked_by" array
*/
function is_liked(user_id, liked_by) {
  if (liked_by == null) {
    return false;
  }
  else {
    return liked_by.includes(user_id)
  }
}

function ListingView(props) {
  const app = useRealmApp();
  // below *might* be needed if the users that like the listing matter?
  // This probably should be hidden from users anyway and moved up into a server side function
  //const [liked_by_array, setLikedByArray] = useState()
  const [isLiked, setLiked] = useState(is_liked(app.currentUser.customData._id, props.listingData.listing.liked_by));

  let params = useParams();
  let listing_id = params.listingID;
  const [buyNowModalOpen, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let clothing_item = props.listingData.listing.clothing_item;

  let available_buttons =
    (<>
      <Row sx={{
        alignItems: 'center',
      }}>
        <Button variant="contained"
          onClick={() => {
            app.currentUser.functions.likeListing(listing_id);
            setLiked(true);
          }}
          sx={{
            textTransform: 'none',
            marginBottom: '15px',
            marginTop: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '75%',
            color: '#FFFFFF',
            borderRadius: '25px',
          }}><b>I'd Shuffle That&emsp;</b>
          <img src="/images/trade_arrow.svg" alt="trade_arrow" height="22px" />
        </Button>
      </Row>
      <Row sx={{
        alignItems: 'center',
      }}>
        <Button variant="contained" sx={{
          textTransform: 'none',
          width: '75%',
          backgroundColor: '#153b2c',
          color: '#FFFFFF',
          borderRadius: '25px',
          paddingTop: '10px',
          paddingBottom: '10px',
          marginBottom: '15px',
        }}
          onClick={handleOpen}>
          <b>Buy Now ${props.listingData.listing.asking_price}</b>
        </Button>
        <BuyNowModal open={buyNowModalOpen} onClose={handleClose} listing_id={listing_id} />
      </Row>
    </>);

  if (isLiked) {
    available_buttons =
      (<>
        <Row sx={{
          alignItems: 'center',
        }}>
          <Button variant="contained"
            onClick={() => {
              app.currentUser.functions.unlikeListing(listing_id);
              setLiked(false);
            }}
            sx={{
              textTransform: 'none',
              marginBottom: '15px',
              marginTop: '5px',
              paddingTop: '10px',
              paddingBottom: '10px',
              width: '75%',
              color: '#FFFFFF',
              backgroundColor: '#E11C24',
              borderRadius: '25px',
            }}><b>Remove from Saved Listings&emsp;</b>
          </Button>
        </Row>
        <Row sx={{
          alignItems: 'center',
        }}>
          <Button variant="contained" sx={{
            textTransform: 'none',
            width: '75%',
            backgroundColor: '#153b2c',
            color: '#FFFFFF',
            borderRadius: '25px',
            paddingTop: '10px',
            paddingBottom: '10px',
            marginBottom: '15px',
          }}
            onClick={handleOpen}>
            <b>Buy Now ${props.listingData.listing.asking_price}</b>
          </Button>
          <BuyNowModal open={buyNowModalOpen} onClose={handleClose} listing_id={listing_id} />
        </Row>
      </>);
  }

  if (app.currentUser.id === props.listingData.listing._partition.slice(5)) {
    available_buttons = (
      <>
        <Row sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Link to={
            `/edit_listing/${listing_id}`
          } style={{ width: '60%', justifyContent: 'center', display: 'flex', textDecoration: 'none', }}>
            <Button variant="contained" sx={{
              textTransform: 'none',
              width: '100%',
              color: '#FFFFFF',
              borderRadius: '25px',
              marginBottom: '10px',
            }}>
              Edit Listing
            </Button>
          </Link>
        </Row>
      </>
    )
  }

  let descriptors = clothing_item.additional_descriptors.map((desc) => (
    <ListingChip key={desc} label={desc} />
  ));

  return (
    <Container disableGutters
      id="ViewListingPort"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingTop: 5,
        paddingBottom: 12,
        backgroundColor: '#fbfbfb',
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Box sx={{
        width: '90%',
        marginLeft: '5%'
      }}>
        <ImageGallery images={clothing_item.photos}
          editing={false}
          new_images={[]} />
      </Box>
      <Box sx={{
        flexDirection: 'column',
        marginTop: '0%',
        marginBottom: '0%',
        alignItems: 'center',
      }}>
        <Row sx={{
          width: "100%",
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Brand sx={{
            marginBottom: '0px',
            marginLeft: '5%',
          }}>{clothing_item.brand}</Brand>
          <Location sx={{
            marginBottom: '0px',
            marginRight: '5%',
            whiteSpace: 'nowrap',
          }}><MapPin />Shuffle</Location>
        </Row>
        <Row sx={{
          marginTop: 'inherit',
        }}>
          <ItemName sx={{
            marginTop: '0px',
            marginLeft: '5%',
          }}>{clothing_item.item_name}</ItemName>
        </Row>
        {available_buttons}
        <Box sx={{
          backgroundColor: 'rgba(130,214,171, 0.3)',
          paddingBottom: '5px',
          paddingTop: '5px',
        }}>
          <DescriptionP>Size: {clothing_item.size}</DescriptionP>
          <DescriptionP>Color: {clothing_item.color_pattern}</DescriptionP>
          <DescriptionP>Condition: {clothing_item.condition}</DescriptionP>
        </Box>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          marginTop: '10px',
          justifyContent: 'center',
          marginLeft: '5%',
          marginRight: '5%',
        }}>
          {descriptors}
        </Box>
      </Box>
    </Container>
  );
}

function BuyNowModal(props) {

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        width: 'calc(100% - 60px)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
      }}>
        <Button sx={{
          display: 'inline-block',
          position: 'absolute',
          right: '5px',
          marginLeft: 'auto',
          top: '15px',
          padding: 0,
        }}
        onClick={props.onClose}>
          <X color="#707070"/>
        </Button>

        <h2>Purchasing Instructions</h2>
        <p>To purchase this item, send an email to <a href={`mailto:morgan@shuffle.exchange?subject=I'd like to purchase listing ${props.listing_id}&body=Your Venmo/PayPal details to receive payment request from Shuffle`}>morgan@shuffle.exchange</a>.<br /><br />
          Subject: "I'd like to purchase listing {props.listing_id}"<br /><br />
          Email Body: Your Venmo/PayPal details to receive payment request from Shuffle<br /><br />
          After we receive your payment, we will arrange for a free delivery of the item if that item is currently at Shuffle. Otherwise, we will coordinate with you and the seller on shipping/delivery.
        </p>
      </Box>
    </Modal>
  );
}

const Brand = styled('h3')({
  color: "#153b2c",
});

const ItemName = styled('p')({
  color: "#153b2c",
});

const Location = styled('p')({
  color: "#153b2c",
  marginLeft: 'auto',
});
const Row = styled(Box)({
  flexDirection: 'column',
  display: 'flex',
});

const DescriptionP = styled('p')({
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '5%',
});