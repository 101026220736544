import gql from "graphql-tag";

// This is the mutation
const OneListing = gql`
  mutation AddListing($inputValue: ListingInsertInput!) {
    addedListing: insertOneListing(data: $inputValue) {
      _id
      _partition
      _version
      _price_range
      asking_price
      liked_by 
      clothing_item {
        brand
        item_name
        size
        color_pattern
        photos
        condition
        additional_descriptors
      }
      delivery_info {
        status
        option
      }
    }
  }
`;

const GetListingsFeed = gql`
  query listings($input: String) {
    listings(query: {_partition: $input}, limit: 500){
      _id
      clothing_item {
        item_name
        brand
        size
        color_pattern
        photos
      }
    }
  }
`

const ProfileMyListingsFeed = gql`
  query listings($input: String) {
    listings(query: { _partition : $input}, limit: 6){
      _id
      clothing_item {
        item_name
        photos
      }
    }
  }
`
const ProfileSavedListingsFeed = gql`
  query listings($input: [String]) {
    listings(query: { liked_by : $input}, limit: 6){
      _id
      clothing_item {
        item_name
        photos
      }
    }
  }
`

const SavedListingsQuery = gql`
  query UserLikedListingsQuery($input: String!) {
    UserLikedListingsQuery(input: $input) {
      _id
      asking_price
      clothing_item {
        item_name
        brand
        photos
        size
      }
    }
  }
`

const SingleListingsFeed = gql`
  query listing($input: ObjectId!) {
    listing(query: { _id: $input}){
      _id
      clothing_item {
        item_name
        brand
        size
        color_pattern
        photos
      }
    }
  }
`

const ViewListing = gql`
  query listing($input: ObjectId!) {
    listing(query: { _id: $input }) {
      _id 
      _partition
      asking_price
      liked_by
      clothing_item {
        item_name
        brand
        size
        condition
        color_pattern
        photos
        additional_descriptors
      }
    }
  }
`

const DeleteListing = gql`
  mutation ($input: ObjectId!) {
    deleteOneListing(query: {_id: $input}){
      _id
    }
  }
`
const UpdateListing = gql`
  mutation UpdateListing($query_input: ObjectId, $new_data: ListingUpdateInput!) {
    updatedListing: updateOneListing(query: {_id: $query_input}, set: $new_data) {
      _id
      _partition
      _version
      _price_range
      asking_price
      clothing_item {
        brand
        item_name
        size
        color_pattern
        photos
        condition
        additional_descriptors
      }
      delivery_info {
        status
        option
      }
    }
  }
`;


export { OneListing, 
         GetListingsFeed, 
         ViewListing, 
         ProfileMyListingsFeed,
         ProfileSavedListingsFeed,
         SavedListingsQuery,
         SingleListingsFeed,
         DeleteListing,
         UpdateListing,
        };