import React, { useEffect } from 'react';
import  { NavLink, } from "react-router-dom";
import { Home, Search, PlusCircle, Star, User } from 'react-feather';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import "../CSS/theme.scss";

export default function NavBar(props) {
  const [value, setValue] = React.useState(0);
  
  useEffect(() =>{
    let active = detectActive();
    setValue(active);
  }, []);

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        paddingBottom: 2,
        backgroundColor: '#fbfbfb',
      }} 
      elevation={3}
    >
      <nav>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            padding: 1,
            backgroundColor: '#fbfbfb',
          }}
        >
          <BottomNavigationAction label="Home" icon={<Home color="#82C6AB" style={{transform: 'scale(1.5)'}}/>} component={NavLink} to="/" sx={{
            marginLeft: 5,  
          }}/>
          <BottomNavigationAction id="nav_explore" label="Explore" icon={<Search color="#82C6AB" style={{transform: 'scale(1.5)'}}/>} component={NavLink} to="/explore"/>
          <BottomNavigationAction id="nav_new" label="New" icon={<PlusCircle color="#82C6AB" style={{transform: 'scale(1.5)'}}/>} component={NavLink} to="/new_listing"/>
          <BottomNavigationAction id="nav_notifications" label="Notifications" icon={<Star color="#82C6AB" style={{transform: 'scale(1.5)'}}/>} component={NavLink} to="/notifications"/>
          <BottomNavigationAction id="nav_profile" label="Profile" icon={<User color="#82C6AB" style={{transform: 'scale(1.5)'}}/>} component={NavLink} to="/profile" sx= {{
            marginRight: 5,  
          }}/>
        </BottomNavigation>
      </nav>
    </Paper>
  );
};

function detectActive(){
  let nav_bar_ids = ["nav_explore", "nav_new", "nav_notifications", "nav_profile"];
  let active = 0;
  for (let i=0; i < nav_bar_ids.length; i++) {
    if (document.getElementById(nav_bar_ids[i]).classList.contains("active")) {
      active =  i + 1;
    }
  };
  return active;
}  