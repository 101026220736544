// React
import React from "react";
// React Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
// MongoDB Realm
import { useRealmApp } from "./RealmApp";
// Shuffle Local
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import NewListing from "./pages/NewListing";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import Listings from "./pages/Listings";
import Listing from "./components/Listing";
import NavBar from "./components/Navbar";
import MyListings from "./pages/MyListings";
import SavedListings from "./pages/SavedListings";
import ProfileSettings from './components/ProfileSettings';
import Help from "./pages/Help";
//
import Container from '@mui/material/Container';


export default function ShuffleApp() {
  const app = useRealmApp();
  // Fetches the latest data from the database to avoid stale data
  let update_stale_data = async function () { await app.currentUser.refreshCustomData() };
  update_stale_data();
  // End

  return (
    <Router>
      <Container className="ViewContainer" sx={{
        padding: 0,
        height: 'calc(100vh - 88px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fbfbfb',
        minHeight: "-webkit-fill-available",
      }}>
        <Routes>
          <Route path="explore" element={<Explore />} />
          <Route path="new_listing" element={<NewListing />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="profile" element={<Outlet />}>
            <Route path="" element={<Profile />} />
            <Route path="settings" element={<ProfileSettings />} />
          </Route>
          <Route path="listings" element={<Listings />}>
            <Route path=":listingID" element={<Listing />} />
          </Route>
          <Route path="edit_listing/:listingID" element={<NewListing />} />
          <Route path="/" element={<Home />} />
          <Route path="my_listings" element={<MyListings />} />
          <Route path="saved_listings" element={<SavedListings />} />
          <Route path="help" element={<Help/>}/>  
        </Routes>
      </Container>
      <NavBar />
    </Router>
  );
};