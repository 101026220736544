// Material UI
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
// React Router
import { Link } from 'react-router-dom';
// Apollo Client
import { useQuery } from '@apollo/client';
import { SingleListingsFeed } from '../graphql/ListingMutations';

const Heading = styled('h1')({
  marginLeft: "15px",
  color: "#153b2c",
  width: 'calc(100% - 10px)',
  textTransform: 'uppercase',
  fontSize: '1.4em',
});

const TextContent = styled('p')({
  marginLeft: 10,
  marginRight: 10,
  marginTop: 0,
  marginBottom: 0,
});

const ListingCard = styled(Box)({
  display: 'flex',
  flexDirection: { xs: 'row', md: 'row' },
  width: '340px',
  alignItems: 'center',
  overflow: 'hidden',
  boxShadow: 1,
});

const ListingThumb = styled('img')({
  maxHeight: 150,
  maxWidth: 150,
  borderRadius: "5px",
  margin: "12px 12px",
});

const ListingChip = styled(Chip)({
  margin: "0 5px 5px 0",
  color: "#FBFBFB",
  backgroundColor: "#82C6AB",
});

const SizeChip = (props) => {
  if (props.size != "") {
    return (
      <ListingChip label={props.size}/>
      );
  }
  else {
    return (
      null
    );
  }
}

function FeedListing(props) {
  return (
    <Link to={`/listings/${props.listing_id}`} style={{
      textDecoration: "none",
    }}>
      <ListingCard>
        <ListingThumb src={props.src} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 1,
            marginRight: 1,
            overflowX: 'hidden',
          }}
        >
          <Box sx={{
            fontWeight: "600",
            color: "#153B2C",
            fontSize: "1.1rem",
            marginBottom: "2%",
          }}>{props.brand}</Box>
          <Box sx={{
            fontWeight: "400",
            color: "#153B2C",
            marginBottom: "5%",
          }}>{props.title}</Box>
          <Grid>
            <SizeChip size={props.size}/>
          </Grid>
        </Box>
      </ListingCard>
    </Link>
  );
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

function ListingFeed(props) {
  const { loading, error, data } = useQuery(props.query, 
    {
      variables: props.query_args,
    });
  if (loading) return "Loading";
  if (error) return "Error: " + error.message;

  return (
    <>
      {
        shuffleArray(data.listings.map(listing => (
          <FeedListing key={listing._id}
            listing_id={listing._id}
            src={listing.clothing_item.photos[0]}
            title={listing.clothing_item.item_name}
            brand={listing.clothing_item.brand}
            size={listing.clothing_item.size}
          />
        )))
      }
    </>
  )
}

function SavedListingFeed(props) {
  const { loading, error, data } = useQuery(props.query, 
    {
      variables: props.query_args,
    });
  if (loading) return "Loading";
  if (error) return "Error: " + error.message;

  return (
    <>
      {
        shuffleArray(data.UserLikedListingsQuery.map(listing => (
          <FeedListing key={listing._id}
            listing_id={listing._id}
            src={listing.clothing_item.photos[0]}
            title={listing.clothing_item.item_name}
            brand={listing.clothing_item.brand}
            size={listing.clothing_item.size}
          />
        )))
      }
    </>
  )
}

function SingleListing(props) {
  const { loading, error, data } = useQuery(SingleListingsFeed,
    {
      variables: { 'input': props.objectID},
    });
  
  if (loading){
    return (
      <></>
    )
  }
  if (data){
  return (
    <FeedListing key={data.listing._id}
      listing_id={data.listing._id}
      src={data.listing.clothing_item.photos[0]}
      title={data.listing.clothing_item.item_name}
      brand={data.listing.clothing_item.brand}
      size={data.listing.clothing_item.size}>
        A Listing
      </FeedListing>
  );
  }
  if (error)
  return (
    <h1>Error</h1>
  );
}

export { Heading, TextContent, ListingCard, ListingThumb, FeedListing, ListingChip, ListingFeed, SingleListing, SavedListingFeed,};