import React, { useState } from 'react';

import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { Camera, PlusCircle } from 'react-feather';


export default function ImageGallery(props){
  /* props
  - images: the photos array
  - addPhotos: function to add photos to the array
  - deletePhoto: function to delete a photo at an index
  - editing: boolean to show if this is an editable gallery
  */
  const [ activePhoto, setPhoto ] = useState(0); 
  const [ render_counter, reRender] = useState(0);

const ImagePlaceHolder = (input_id) => {
  return (
    <Box sx={{
      width: "90%",
    }}>
    <input type="file"
        id="listing_pictures" name="listing_pictures"
        accept="image/png, image/jpeg" multiple onChange={(e) => {
            props.addPhotos(e).then(() => {
            setPhoto(0);
            reRender(render_counter + 1);
            //console.log(props.new_images);
            console.log("rerender!?");
          }
          );
        }} 
        style={{ display: "none" }} />
    <label key="placeholder" htmlFor={input_id} style={{ width: "90%",}}>
      <Paper
        sx={{
          height: 400,
          textAlign: 'center',
          border: 1,
          flexShrink: '0',
          display: 'block',
          borderColor: "#707070"
        }}
      >
        <div style={{
          width: "100%",
          marginTop: "150px",
        }}>
          <Camera size={75} strokeWidth={1} color={'#707070'}/>
        </div>
        <div style={{ width: "100%" }}><PlusCircle size={40} strokeWidth={1} color={"#707070"} /></div>
      </Paper>
    </label>
    </Box>
  );
};


  let thumb_photos = [];
  for (let i = 0; i < props.images.length; i++) {
    thumb_photos.push(
      <ThumbPhoto key={i} src={props.images[i]} onClick={()=>{setPhoto(i)}}/>
    );
  }
  if (props.editing) {
    for (let i = 0; i < props.new_images.length; i++) {
      thumb_photos.push(
        <ThumbPhoto key={i} src={props.new_images[i]} onClick={()=>{setPhoto(i)}}/>
      );
    }
    thumb_photos.push(
      <div><input type="file"
        id="listing_pictures" name="listing_pictures"
        accept="image/png, image/jpeg" multiple onChange={(e) => {
            props.addPhotos(e).then(() => {
            setPhoto(0);
            reRender(render_counter + 1);
            console.log("rerender!?");
          }
          );
        }} 
        style={{ display: "none" }} />
        {addImageThumb("listing_pictures")}
      </div>
    );
  }

  if (props.images.length === 0 && props.new_images.length ===0) {
    if (props.editing) {
      return ImagePlaceHolder("listing_pictures")
    }
  }


  let gallery_images = [...props.images];
  let remove_button = null;
  if (props.editing) {
    gallery_images = [...props.images, ...props.new_images];
    remove_button = (<Button style={{marginTop: '5px', marginBottom: '5px'}} variant='contained' color='error' 
      onClick={() => {
        props.remove_photo(activePhoto); 
        setPhoto(0); 
        reRender(render_counter + 1);
      }}>Remove Photo
    </Button>);
  }
  return(
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '100%',
    }}>
      <ActivePhoto src={gallery_images[activePhoto]} style={{
        maxWidth: '100%',
        maxHeight: '400px',
      }}/>
      {remove_button}
      <Box sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '10px',
        width: '100%',
        overflowX: 'scroll',
        marginTop: '5px',
        //height: '100px',
      }}>
        {thumb_photos}
      </Box>
    </Box>
  );
}

const ActivePhoto = styled('img')({
  maxWidth: '90%',
  borderRadius: '2%',
  maxHeight: '4500px',
  objectFit: 'cover',
});

const ThumbPhoto = styled('img')({
  maxHeight: '100px',
  maxWidth: '150px',
  width: 'auto',
  borderRadius: '5px',
  objectFit: 'cover',
});


const addImageThumb = (input_id) => {
  return (
    <label key="placeholder" htmlFor={input_id} style={{ width: '75px'}} >
      <Paper sx={{
        textAlign: 'center',
        width: '98px',
        border: 1,
        flexShrink: '0',
        display: 'block',
        bordereColor: '#707070',
      }}>
        <div style={{
          width: '100%',
          marginTop: '13px',
        }}>
          <Camera size={55} strokeWidth={1} color={'#707070'}/>
        </div>
        <div>
          <PlusCircle size={20} strokeWidth={1} color={'#707070'} />
        </div>
      </Paper>
    </label>
  )
}
