// React
import React from "react";
// Realm
import * as Realm from "realm-web";
// Material UI
import { styled } from "@mui/system";
import { CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// Shuffle Custom
import { useRealmApp } from "../RealmApp";
import { handleAuthenticationError } from "./Utils";

import validator from "validator";

export default function Login(props) {
  /*
    Props:
    - toggleCreateAccount: function to switch to create account page view
  */
  const app = useRealmApp();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [error, setError] = React.useState("");

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      await app.logIn(Realm.Credentials.emailPassword(email.toLowerCase(), password));
    } catch (err) {
      setError("Invalid username or password")
      //handleAuthenticationError(err, setError);
      setIsLoggingIn(false);
    }
  }

  return (
    <LoginContainer>
      {isLoggingIn ?
        (
          <CircularProgress />
        ) : (
          <>
            <img src="/images/shuffle_logo_official.png"
              alt="Logo" 
              width="80%"
              style={{ maxWidth: 300, marginTop: '75px' }}
            />
            <Box sx={{
              width: "80%",
              maxWidth: '500px',
              marginTop: '60px',
              textAlign: 'center',
            }}>
              <LoginError error={error}/>
              <LoginFormRow>
                <LoginField
                  variant="standard"
                  label="Email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </LoginFormRow>
              <LoginFormRow>
                <LoginField
                  variant="standard"
                  type="password"
                  label="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </LoginFormRow>
              <LoginFormRow>
                <Button variant="contained" onClick={() => handleLogin()} sx={{
                  borderRadius: "25px",
                  width: "80%",
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  textTransform: 'unset',
                  maxWidth: '350px',
                  fontSize: '16px',
                }}>
                  Sign In
                </Button>
              </LoginFormRow>
              <LoginFormRow>
                <ForgotPassword sx={{
                  marginTop: '20px',
                  color: '#153b2c',
                }}
                  onClick={() => {
                    alert("Please email support@shuffle.exchange for a password reset")
                  }}>Forgot Password?</ForgotPassword>
              </LoginFormRow>
              <ToggleContainer>
                <ToggleText>
                  New to Shuffle?
                </ToggleText>
                <ToggleLink
                  onClick={(e) => {
                    e.preventDefault();
                    props.toggleCreateAccount();
                  }}
                >
                  <b style={{ color: "#153b2c", }}>Sign Up</b>
                </ToggleLink>
              </ToggleContainer>
            </Box>
          </>
        )
      }
    </LoginContainer>
  );
}

const LoginContainer = styled(Box)({
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: '#fbfbfb',
});

const ToggleLink = styled(Button)({
  background: "none",
  textTransform: "none",
  fontSize: '16px',
});

const LoginHeading = styled(Box)({
  margin: 0,
  fontSize: 32,
});

const LoginFormRow = styled(Box)({
  marginBottom: 2,
});

const ToggleText = styled(Box)({
  lineHeight: 10,
  fontSize: '16px',
});

const ToggleContainer = styled(Box)({
  marginTop: 8,
  fontSize: 12,
  display: 'flex',
  justifyContent: 'center',
});

const LoginField = styled(TextField)({
  width: '100%',
  fontSize: '16px',
});

const ForgotPassword = styled(Button)({
  background: "none",
  textTransform: "none",
  fontSize: '16px',
})

function LoginError(props){
  if (props.error === ""){
    return null;
  }
  else {
    return <h3 style={{
      color: "#E11C24",
    }}>
      Error: {props.error}
    </h3>
  }
}