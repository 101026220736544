// React
import React from "react";
// Shuffle Custom Components
import { Heading, TextContent } from "../components/Feed";
// Material UI
import { Container } from "@mui/material";

export default function Notifications() {

  return (
    <Container
      disableGutters
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 88px)',
        backgroundColor: '#fbfbfb',
      }} 
    >
      <Heading>Notifications </Heading>
      <TextContent>
        You have no notifications.
      </TextContent>
    </Container>
  );
}