// React
import React from "react";
// Shuffle Custom Components
import { Heading, SingleListing } from '../components/Feed';
// Material UI
import { Container } from "@mui/material";
import { Box, styled } from '@mui/system';


export default function Home() {

  return (
    <Container
      disableGutters
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fbfbfb',
        paddingBottom: 12,
      }}
    >
      <img src="images/shuffle_logo_official.png" style={{ marginRight: 'auto', maxWidth: '80%', maxHeight: '85px', objectFit: 'contain' }} />
      <ModHeading>Featured</ModHeading>
      <FeaturedCategory>Accessorize</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"618943bb91c95a7d462cbbc0"}></SingleListing>
        <SingleListing objectID={"61fb071633079d5317239147"}></SingleListing>
        <SingleListing objectID={"61893f9d91c95a7d462cbbba"}></SingleListing>
        <SingleListing objectID={"6202960c3f5aef5ea40b9c6d"}></SingleListing>
        <SingleListing objectID={"6189401991c95a7d462cbbbb"}></SingleListing>
        <SingleListing objectID={"620296a52d60aaca4aacbd0b"}></SingleListing>
        <SingleListing objectID={"61fc2f496daaad7a4c950b3f"}></SingleListing>
        <SingleListing objectID={"6208510825b95e362712e8e7"}></SingleListing>
        <SingleListing objectID={"61894a40d32b14a3e9b65632"}></SingleListing>
      </Container>
      <FeaturedCategory>Staying Cozy</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"61e8c7272c8e80d2843e82ee"}></SingleListing>
        <SingleListing objectID={"619ad64b5ca4790e4fa6670c"}></SingleListing>
        <SingleListing objectID={"6189835a2aa169e8a0d7ba26"}></SingleListing>
        <SingleListing objectID={"618982ee2aa169e8a0d7ba25"}></SingleListing>
        <SingleListing objectID={"618431c57e72978037acc51c"}></SingleListing>
        <SingleListing objectID={"61842c57b80cc9c79b56346d"}></SingleListing>
        <SingleListing objectID={"6184314a7e72978037acc51b"}></SingleListing>
        <SingleListing objectID={"61842775b80cc9c79b563463"}></SingleListing>
        <SingleListing objectID={"6184310b7e72978037acc51a"}></SingleListing>
      </Container>
      <FeaturedCategory>At the Gym</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"61815188ae0fdae31bbbd525"}></SingleListing>
        <SingleListing objectID={"618152dbb05472f378667577"}></SingleListing>
        <SingleListing objectID={"61893d7491c95a7d462cbbb8"}></SingleListing>
        <SingleListing objectID={"618159b07c3fc50847f9024c"}></SingleListing>
        <SingleListing objectID={"61815b07d9dee5970769ed57"}></SingleListing>
        <SingleListing objectID={"61815b4b8fc997f94b8e33cf"}></SingleListing>
        <SingleListing objectID={"618430a97e72978037acc519"}></SingleListing>
        <SingleListing objectID={"618944d291c95a7d462cbbc3"}></SingleListing>
        <SingleListing objectID={"619acc601ad9edaf6fdf39ca"}></SingleListing>
      </Container>
      <FeaturedCategory>Warm Days</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"6184065a288f49cfb4d3c543"}></SingleListing>
        <SingleListing objectID={"6184109eeb234972e4bf8840"}></SingleListing>
        <SingleListing objectID={"61842451b80cc9c79b56345e"}></SingleListing>
        <SingleListing objectID={"61842b38b80cc9c79b56346a"}></SingleListing>
        <SingleListing objectID={"618410005a9e79658e8d1077"}></SingleListing>
        <SingleListing objectID={"61843c6fd2cdda2a063d73ad"}></SingleListing>
        <SingleListing objectID={"6189454191c95a7d462cbbc4"}></SingleListing>
        <SingleListing objectID={"619adcfd41653466b614acbc"}></SingleListing>
        <SingleListing objectID={"619acd15a48030afa89170b0"}></SingleListing>
      </Container>
      <FeaturedCategory>Night Out</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"618427f3b80cc9c79b563464"}></SingleListing>
        <SingleListing objectID={"61843bbed2cdda2a063d73ac"}></SingleListing>
        <SingleListing objectID={"619ad85218a17b9da915a8b1"}></SingleListing>
        <SingleListing objectID={"61842d62b80cc9c79b56346f"}></SingleListing>
        <SingleListing objectID={"619ada2f41653466b614acb6"}></SingleListing>
        <SingleListing objectID={"619adc0641653466b614acb9"}></SingleListing>
        <SingleListing objectID={"619adbcc41653466b614acb8"}></SingleListing>
        <SingleListing objectID={"619ad46e5ca4790e4fa66709"}></SingleListing>
        <SingleListing objectID={"619acbecbbc736e632ac15da"}></SingleListing>
      </Container>
      <FeaturedCategory>At the Office</FeaturedCategory>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 4,
      }}>
        <SingleListing objectID={"6189819d2aa169e8a0d7ba22"}></SingleListing>
        <SingleListing objectID={"61842572b80cc9c79b563460"}></SingleListing>
        <SingleListing objectID={"62196d4926ac8624111c647e"}></SingleListing>
        <SingleListing objectID={"61897dd12aa169e8a0d7ba1b"}></SingleListing>
        <SingleListing objectID={"61897ef82aa169e8a0d7ba1d"}></SingleListing>
        <SingleListing objectID={"61840e9cb7f665f55c85c66d"}></SingleListing>
        <SingleListing objectID={"6189824b2aa169e8a0d7ba23"}></SingleListing>
        <SingleListing objectID={"61e8c4ef72be7f534f452ccd"}></SingleListing>
        <SingleListing objectID={"61e8c755f6e40fd58676aae5"}></SingleListing>
      </Container>
    </Container>
  );
}

const FeaturedCategory = styled('h3')({
  color: '#82C6AB',
  margin: '0px 5px 0px 15px',
  fontSize: '1.25rem',
});

const ModHeading = styled(Heading)({
  marginBottom: '5px',
  fontSize: '1.4em',
})