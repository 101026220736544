// React
import React, { useState, useRef } from "react";
// Apollo
import { useMutation, useQuery } from "@apollo/client";
import { ObjectId } from "bson";
// Material-UI
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
// Mongo Realm
import { useRealmApp } from "../RealmApp";
// Validator
import validator from 'validator';
// Shuffle
import { ListingChip } from '../components/Feed';
import { OneListing, ViewListing, DeleteListing, UpdateListing } from '../graphql/ListingMutations';
// React Router
import { useNavigate, useParams } from 'react-router-dom';
import ImageGallery from "../components/ImageGallery";

const S3_URL = "https://shuffle-s3.s3.amazonaws.com/";

export default function NewListing() {
  // Determine if we are creating a new listing or editing an existing listing
  let params = useParams();
  let listing_id = params.listingID;
  const [skip_query, setSkip] = useState(true);
  const [first_render, setFirstRender] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  let navigate = useNavigate();

  let [deleteListing, { loading: deleting, error: deleteError }] = useMutation(
    DeleteListing,
    {
      variables: {
        input: listing_id
      },
    }
  );

  let [editListing, { edit_data, edit_loading, edit_error }] = useMutation(UpdateListing);


  let deleteButton = null;
  let pageTitle = "Create a Listing";

  // Set "Edit" specific values otherwise "New Listing" values are used
  if (listing_id !== undefined) {
    // Use the query data on the first render
    if (skip_query) setSkip(false);

    pageTitle = "Editing Listing";

    deleteButton = (
      <SubmitButton variant='contained' color='error' onClick={async () => {
        let deleted_id = await deleteListing(
          {
            variables: { input: listing_id },
          }
        );
        navigate('/my_listings');
      }}>
        Delete Listing
      </SubmitButton>
    );
  }

  // If we are editing a listing, query to get the current values
  const { loading, error, data } = useQuery(
    ViewListing,
    {
      variables: { input: listing_id },
      skip: skip_query,
    }
  );

  // Set initial values based on new/edit
  const [asking_price, setAskingPrice] = useState("");
  const [price_preview, setPreviewPrice] = useState("$");
  const [asking_price_valid, setAskingPriceValid] = useState(false);
  const [existing_images, setExistingImages] = useState([]);
  const [new_images, setNewImages] = useState([]);
  const [descriptor_array, setDescriptorArray] = useState([]);
  const [brand, setBrand] = useState("");
  const [item_name, setName] = useState("");
  const [condition, setCondition] = useState("");
  const [size, setSize] = useState("");
  const [color_pattern, setColorPattern] = useState("");

  const setAll = (data) => {
    let clothing_item = data.listing.clothing_item;
    setBrand(clothing_item.brand);
    setName(clothing_item.item_name);
    setCondition(clothing_item.condition);
    setSize(clothing_item.size);
    setColorPattern(clothing_item.color_pattern);
    setDescriptorArray(clothing_item.additional_descriptors);
    setAskingPrice(data.listing.asking_price);
    setAskingPriceValid(true);
    setPreviewPrice("$" + data.listing.asking_price);
    setExistingImages([...clothing_item.photos]);
    setFirstRender(false);
  };

  // If editing set the values according to current DB values
  if (data && first_render) {
    setAll(data);
  }

  function changeAskingPrice(new_price) {
    setPreviewPrice(new_price)
    if (validator.isCurrency(new_price, { allow_negatives: false })) {
      try {
        var number = Number(new_price.replace(/[^0-9.-]+/g, ""));
        setAskingPrice(number);
        setAskingPriceValid(true);
      }
      catch {
        setAskingPriceValid(false);
        return;
      }
    }
    else {
      setAskingPrice(new_price);
      setAskingPriceValid(false);
      return;
    }
  }

  // Start Image Input Code
  async function addPhotos(event) {
    const files = event.target.files;
    await Promise.all(
      Array.from(files).map(file => {
        const reader = new FileReader();
        return new Promise(resolve => {
          reader.onload = (e) => {
            resolve(reader.result);
          }
          reader.readAsDataURL(file);
        })
      })).then(loadingImages => {
        console.log("Loading:", loadingImages);
        setNewImages([...new_images, ...loadingImages])
      }
      );
  };
  // End Image Input Code

  // Start Descriptor Code
  const descriptorInput = useRef(null);

  function descriptorFocus() {
    descriptorInput.current.focus();
    descriptorInput.current.value = "";
  }

  function addDescriptor() {
    let descriptor = document.getElementById("descriptor-field").value;
    setDescriptorArray([...descriptor_array, descriptor]);
    descriptorFocus();
  }

  function deleteDescriptor(descriptor) {
    setDescriptorArray((descriptor_array) =>
      descriptor_array.filter((descriptor_item) => descriptor_item !== descriptor)
    );
  }

  const descriptors = descriptor_array.map(h => (
    <ListingChip key={h} label={h} onDelete={() => { deleteDescriptor(h) }} />
  ));
  // End Descriptor Code 

  // Start Realm Upload Code
  const app = useRealmApp();
  const user = app.currentUser;

  const [addOneListing, result] = useMutation(OneListing, {
    variables: {
      inputValue: {
        _price_range: 5.0,
        _version: 0.1,
        comments: [],
        delivery_info: {
          status: "With User",
          option: "Pick-Up",
        },
        _partition: app.currentUser.customData._partition,
      },
    }
  });

  function DisplaySubmitting() {
    if (result.loading) return (<div>Submitting..</div>);
    if (result.error) return (<div>Error</div>);
  }

   function DisplaySubmitting2() {
     if (submitting) return (<h3>Submitting</h3>)
  }

     
      
  async function submitListing(realm_user, l_id) {
    setSubmitting(true);
    if (asking_price_valid !== true) {
      alert("Asking price is not valid!")
      return
    }
    let data_for_submit = {};
    let clothing_item = {};
    clothing_item.brand = document.getElementById('brand').value;
    clothing_item.item_name = document.getElementById('item_name').value;
    clothing_item.condition = document.getElementById('condition').value;
    clothing_item.size = document.getElementById('size').value;
    clothing_item.color_pattern = document.getElementById('color_pattern').value;
    clothing_item.additional_descriptors = descriptor_array;
    clothing_item.photos = [...existing_images];
    data_for_submit.clothing_item = clothing_item;
    data_for_submit.asking_price = asking_price; //parseFloat(document.getElementById('asking_price').value);
    data_for_submit._price_range = 5.0;
    data_for_submit._version = 0.1;
    data_for_submit._partition = app.currentUser.customData._partition;
    data_for_submit.comments = [];
    data_for_submit.liked_by = [];
    if (l_id == undefined) data_for_submit._id = ObjectId();
    else data_for_submit._id = l_id;
    data_for_submit.delivery_info = {
      status: "With_User",
      option: "Pick-Up",
    };

    function get_numbers_taken() {
      let images_taken = existing_images.map(x => {
        try {
          let x_split = x.split('-')
          let number = parseInt(x_split[x_split.length - 1].split('.')[0])
          return number
        }
        catch {
          console.log("Error Getting URL Numbers... skipping this one. May accidentally be replaced");
        }
      })
      return images_taken
    }

    // Add photos to S3
    let image_numbers_taken = get_numbers_taken();
    console.log("Images for upload", new_images);

    for (let i = 0; i < new_images.length; i++) {
      let image_number = i;
      let name_not_found = true;
      let image_name = "";
      while (name_not_found) {
        if (image_numbers_taken.includes(image_number)) {
          image_number += 1
          continue
        }
        image_name = `image-${data_for_submit._id.toString()}-${image_number.toString()}${getExtension(new_images[i])}`;
        image_numbers_taken.push(image_number)
        break
      }
      let contentType = getContentType(new_images[i]);
      console.log("Image:", new_images[i].split(',')[1]);
      console.log("Image Name:", image_name);
      console.log("Image Type:", contentType);
      await app.currentUser.callFunction("Upload_Image_S3", new_images[i].split(',')[1], "shuffle-s3", image_name, contentType); // est_s3", args);  //"Upload_Image_S3", args);

      let image_url = S3_URL + image_name;
      data_for_submit.clothing_item.photos.push(image_url);
    }

    // Add listing

    if (l_id == undefined) {
      let listing_id = await addOneListing({ variables: { inputValue: data_for_submit } });
      let current_hostname = window.location.hostname;
      let current_port = window.location.port;
      let current_prot = window.location.protocol;
      window.location.href = `${current_prot}//${current_hostname}:${current_port}/listings/${data_for_submit._id}`;

      /*
      let listing_id = Promise.resolve(addOneListing({ variables: { inputValue: data_for_submit } })
        .then((resolve) => resolve.data)
        .then((listing_mutation_data) => {
          let current_hostname = window.location.hostname;
          let current_port = window.location.port;
          let current_prot = window.location.protocol;
          window.location.href = `${current_prot}//${current_hostname}:${current_port}/listings/${data_for_submit._id}`;

          return (listing_mutation_data.addedListing._id);
        }));
        */
    }
    else {
      let listing_id = await editListing({ variables: { query_input: l_id, new_data: data_for_submit } });
      let current_hostname = window.location.hostname;
      let current_port = window.location.port;
      let current_prot = window.location.protocol;
      window.location.href = `${current_prot}//${current_hostname}:${current_port}/listings/${data_for_submit._id}`;

      /*
      editListing({ variables: { query_input: l_id, new_data: data_for_submit } });
      Promise.resolve(editListing({ variables: { query_input: l_id, new_data: data_for_submit } })
        .then((resolve) => resolve.data)
        .then((listing_mutation_data) => {
          let current_hostname = window.location.hostname;
          let current_port = window.location.port;
          let current_prot = window.location.protocol;
          window.location.href = `${current_prot}//${current_hostname}:${current_port}/listings/${data_for_submit._id}`;

          return (listing_mutation_data.addedListing._id);
        })
      );
    */
    }
    setSubmitting(false);
  }
  // End Realm Upload Code

  return (
    <Container id="NewListingPort"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        paddingBottom: 12,
        backgroundColor: '#fbfbfb',
      }}
    >
      <h1 style={{ color: "#153b2c" }}>{pageTitle}</h1>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%'
      }}>
        <ImageGallery images={existing_images}
          new_images={new_images}
          editing={true}
          addPhotos={addPhotos}
          remove_photo={(i) => {
            if (i < existing_images.length) {
              let images_copy = existing_images;
              images_copy.splice(i, 1);
              setExistingImages(images_copy);
            }
            else {
              let images_copy = new_images;
              images_copy.splice(i - existing_images.length, 1);
              setNewImages(images_copy);
            }
          }} />
      </Box>
      <ListingTextField id="brand" label="Brand" variant="standard" value={brand}
        onChange={(e) => setBrand(e.target.value)} />
      <ListingTextField id="item_name" label="Item Name" variant="standard" value={item_name}
        onChange={(e) => setName(e.target.value)} />
      <ListingTextField id="asking_price" label="Asking Price (Example: $5.00)" variant="standard" value={price_preview}
        onChange={(e) => changeAskingPrice(e.target.value)} />
      <ListingTextField id="condition" label="Condition" variant="standard" value={condition}
        onChange={(e) => setCondition(e.target.value)} />
      <ListingTextField id="size" label="Size" variant="standard" value={size}
        onChange={(e) => setSize(e.target.value)} />
      <ListingTextField id="color_pattern" label="Color/Pattern" variant="standard" value={color_pattern}
        onChange={(e) => setColorPattern(e.target.value)} />
      <h3 style={{ color: "#153b2c", marginTop: "30px", fontWeight: "500" }}>Add Keywords</h3>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <TextField id="descriptor-field" placeholder="Short-Sleeves, etc." inputRef={descriptorInput}></TextField>
        <Button variant="contained" onClick={addDescriptor} style={{ width: "80px", height: "35px", marginLeft: "10px" }}>Add</Button>
      </Box>
      <Box marginTop={"15px"}>
        {descriptors}
      </Box>
      <SubmitButton variant="contained"
        onClick={() => {
          submitListing(user, listing_id)
        }}>
        Submit Listing
      </SubmitButton>
      {deleteButton}
      {DisplaySubmitting()}
      {DisplaySubmitting2()}
    </Container>
  );
}

const SubmitButton = styled(Button)({
  display: 'block',
  fontSize: '1rem',
  lineHeight: '3.0',
  flexBasis: '0',
  display: 'inline',
  marginTop: '5px',
  marginBottom: '5px',
  width: '50%',
});

const ListingTextField = styled(TextField)({
  width: "90%",
  marginTop: '10px',
});

const DeliveryOption = styled(Box)({
  margin: 10,
  display: 'flex',
  flexDirection: 'column',
  flexBasis: "33%",
  flexGrow: 0,
  alignItems: "center",
  textAlign: "center",
});

function getExtension(base64_encoded_string) {
  var end_header = base64_encoded_string.indexOf(',');
  var first_character = base64_encoded_string[end_header + 1];

  if (first_character === "/") {
    return ".jpeg";
  }
  if (first_character === "i") {
    return ".png";
  }
  if (first_character === "R") {
    return ".gif";
  }
  return null;
}

function getContentType(base64_encoded_string) {
  var end_header = base64_encoded_string.indexOf(',');
  var first_character = base64_encoded_string[end_header + 1];

  if (first_character === "/") {
    return "image/jpeg";
  }
  if (first_character === "i") {
    return "image/png";
  }
  if (first_character === "R") {
    return "image/gif";
  }
  return null;
}

const DeliveryText = styled('span')({
  height: '50px',
});