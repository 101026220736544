import React from "react";
import { Heading, TextContent } from '../components/Feed';
import { styled } from '@mui/system';


export default function Help(props) {


  return (
    <>
      <Heading>Help</Heading>
      <SubHeading>Contact Shuffle</SubHeading>
        <p>We're always here to help! If you run into any problems using the webapp, please reach out to 
          us and we will resolve the issue. You can reach us at 978-660-7877 or send us an e-mail to support@shuffle.exchange
        </p>
      <SubHeading>Tutorial</SubHeading>
    </>
  );
}

const SubHeading = styled(Heading)({
  fontSize: '1rem',
})

