import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

export default function Listings() {

  return (
    <Container
    sx={{
        display: 'flex',
        flexDirection:'column',
        padding: 0,
        backgroundColor: '#fbfbfb',
        maxWidth: '600px',
        width: '100%',
    }}>
      <Outlet />
    </Container>
  );
}