// React
import React from "react";
// Material UI
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// Realm
import * as Realm from "realm-web";
// Shuffle Custom
import { useRealmApp } from "../RealmApp";
import { handleAuthenticationError, ShuffleForm } from "./Utils";
//
import validator from "validator";

export default function SignUp(props) {
  /*
    Props:
    - toggleLogin: function to switch to the login form
  */
  const app = useRealmApp();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");

  const [error, setError] = React.useState({});
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const handleLogin = async () => {
    setIsLoggingIn(true);
    setError((e) => ({ ...e, password: null }));
    try {
      const user_profile_data = {
        "user_info": {
          "first_name": firstName,
          "last_name": lastName,
          "phone_number": phoneNumber,
          "email": email.toLowerCase(),
          "profile_img_uri": "",
          "reputation": 0,
          "num_of_completed_trades": 0,
          "location": {
            "city": city,
            "state": state,
          }
        }
      };
      props.setUserProfile(user_profile_data);
      console.log("Logging in");
      return await app.logIn(Realm.Credentials.emailPassword(email.toLowerCase(), password))
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  }

  const handleRegistrationAndLogin = async () => {
    const isValidEmailAddress = validator.isEmail(email);
    setError((e) => ({ ...e, password: null }));
    if (isValidEmailAddress) {
      try {
        await app.emailPasswordAuth.registerUser(email.toLowerCase(), password);
        return await handleLogin();
      } catch (err) {
        handleAuthenticationError(err, setError);
      }
    } else {
      setError((err) => ({ ...err, email: "Email is invalid." }));
    }
  };


  return (
    <LoginContainer>
      {isLoggingIn ?
        (
          <CircularProgress />
        ) :
        (
          <>
            <img src="/images/shuffle_logo_official.png" alt="Logo" width="80%" style={{ maxWidth: 300, marginTop: '75px' }}></img>
            <SignUpPageRow>
              Already have an account?
              <ToggleLink onClick={props.toggleLogin}><b>Sign In</b></ToggleLink>
            </SignUpPageRow>
            <SignUpPageRow>
              <ShuffleForm
                fields={[
                  { field: email, setter: setEmail, name: 'Email' },
                  { field: password, setter: setPassword, name: 'Password' },
                  { field: confirmPassword, setter: setConfirmPassword, name: 'Confirm Password' },
                  { field: firstName, setter: setFirstName, name: 'First Name' },
                  { field: lastName, setter: setLastName, name: 'Last Name' },
                  { field: city, setter: setCity, name: "City" },
                  { field: state, setter: setState, name: "State" },
                  { field: phoneNumber, setter: setPhoneNumber, name: 'Phone Number' },
                        ]}
                submitF={handleRegistrationAndLogin}
                submitText={"Create Account"}
              />
            </SignUpPageRow>
          </>
        )
      }
    </LoginContainer>
  );
}

const ToggleLink = styled(Button)({
  background: "none",
  textTransform: "none",
  fontSize: '16px',
  color: '#153b2c',
});

const LoginContainer = styled(Box)({
  height: '100vh',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: '#fbfbfb',
});

const SignUpPageRow = styled(Box)({
  marginBottom: 2,
  width: '100%',
  textAlign: 'center',
});