import React from "react";
import { useRealmApp } from "../RealmApp";
import {
  ApolloClient, 
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const createRealmApolloClient = (app) => {
  const link = new HttpLink({
    uri: 'https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/shuffle-0-vteac/graphql',//`https://realm.mongodb.com/api/client/v2.0/${app.id}/graphql`,
    fetch: async( uri, options) => {
      if (!app.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      await app.currentUser.refreshCustomData();
      options.headers.Authorization = `Bearer ${app.currentUser.accessToken}`;
      return fetch(uri, options);
    },
  });

  const cache = new InMemoryCache();

  return new ApolloClient({ link, cache });
};

export default function RealmApolloProvider({ children }) {
  const app = useRealmApp();
  const [client, setClient] = React.useState(createRealmApolloClient(app));
  React.useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}