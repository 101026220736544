import React from "react";
import { useRealmApp, RealmAppProvider } from "./RealmApp";
import RealmApolloProvider from "./graphql/RealmApolloProvider";

import LoginScreen from  "./pages/LoginScreen";
import ShuffleApp from "./ShuffleApp";
import './App.css';
import { ThemeProvider, createTheme } from "@mui/material";

export const APP_ID = "shuffle-0-vteac"

const RequireLoggedInUser = ({ children }) => {
  const app = useRealmApp();
  const [userProfile, setUserProfile] = React.useState({});

  if (app.currentUser !== null){
    if(Object.keys(userProfile).length == 1){
      console.log("Creating Account");
      console.log(userProfile);
      app.currentUser.callFunction("createAccount", userProfile);
      setUserProfile({});
    }
  }
  /*
  React.useEffect(() => {
    if (app.currentUser !== null){
      console.log(userProfile);
      console.log(Object.keys(userProfile).length);
      if(Object.keys(userProfile).length > 4){
        console.log("Creating Account");
        console.log(userProfile);
        app.currentUser.callFunction("createAccount", userProfile);
        setUserProfile({});
      }
    }
  }, [userProfile, app]);

  */
  return app.currentUser ? children : <LoginScreen setUserProfile={setUserProfile}/>
};

export default function App() {
  return (
    <RealmAppProvider appId={APP_ID}>
      <ThemeProvider theme={ShuffleTheme}>
        <RequireLoggedInUser>
          <RealmApolloProvider>
            <ShuffleApp />
          </RealmApolloProvider>
        </RequireLoggedInUser>
      </ThemeProvider>
    </RealmAppProvider>
  );
}

export const ShuffleTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#82c6ab',
    },
    secondary: {
      main: '#153b2c',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontWeight: 400,
    },
  },
});