// React
import React from "react";
// GraphQL Query
import { GetListingsFeed } from "../graphql/ListingMutations";
// Custom Components
import { Heading, ListingFeed } from '../components/Feed';
import { useRealmApp } from '../RealmApp';
// Material UI
import { Container } from "@mui/material";

export default function Explore() {
  const app = useRealmApp();

  const user_id = app.currentUser.customData._id;

  return(
    <Container
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fbfbfb',
        paddingBottom: 12,
      }} 
    >
    <Heading>Explore</Heading>
    <Container sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    }}>
      <ListingFeed query={GetListingsFeed} ></ListingFeed>
    </Container>
    </Container>
  );
}
