// React
import React from "react";
// Shuffle
import { useRealmApp } from "../RealmApp";
// GraphQL Mutations
import { ProfileMyListingsFeed, ProfileSavedListingsFeed, SavedListingsQuery } from "../graphql/ListingMutations";
import { useQuery } from '@apollo/client';
// Material UI
import { Container } from "@mui/material";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
// Feather
import { Settings } from "react-feather";
// React Router
import { Link } from "react-router-dom";

export default function Profile() {
  const app = useRealmApp();

  let update_stale_data = async function () { await app.currentUser.refreshCustomData() };
  update_stale_data();

  return (
    <Container disableGutters
      sx={{
        width: '100%',
        //display: 'flex',
        //textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 12,
        backgroundColor: '#fbfbfb',
      }}>
      <SettingsLink to="/profile/settings">
        <ShuffleSettings size="30px" />
      </SettingsLink>
      <ProfilePicture src='/images/shuffle_logo_darkgreen_icon.png' />
      <UserInfo>{app.currentUser.customData.user_info.first_name} {app.currentUser.customData.user_info.last_name}</UserInfo>
      <ProfileSection
        heading="My Listings"
        link="/my_listings"
      >
        <MyListingsContent queryType={ProfileMyListingsFeed} input={`user=${app.currentUser.customData._id}`} />
      </ProfileSection>
      <ProfileSection
        heading="Liked Listings"
        link="/liked_listings"
      >
        <SavedListingsContent queryType={SavedListingsQuery} input={app.currentUser.customData._id} />
      </ProfileSection>
    </Container>
  );
}

const ShuffleSettings = styled(Settings)({
  color: '#153b2c',
});

const SettingsLink = styled(Link)({
  marginLeft: 'auto',
  position: 'relative',
  top: '20px',
  right: '20px',
});

const ProfilePicture = styled('img')({
  marginTop: '100px',
  marginBottom: '15px',
  height: '125px',
  width: '125px',
  objectFit: 'scale-down',
  borderRadius: '50%',
  border: 'solid',
  borderWidth: '1px',
  borderColor: '#153b2c',
});

const ProfileSection = (props) => {

  return (
    <Box sx={{
      maxWidth: '100%',
      minWidth: '350px',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'left',
    }}>
      <Box sx={{
        textAlign: 'left',
        //width: 'calc(100% - 20px)',
        marginLeft: '20px',
      }}>
        <Link to={props.link} style={{ textDecoration: 'none' }}><ProfileHeading>{props.heading}</ProfileHeading></Link>
      </Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '10px',
        //width: 'calc(100% - 40px)',
        flexDirection: 'row',
        overflowX: 'auto',
        marginLeft: '20px',
        marginRight: '20px',
      }}>
        {props.children}
      </Box>
    </Box>
  );
};

const MyListingsContent = (props) => {

  const app = useRealmApp();
  const user_id = app.currentUser.customData._id;
  const query_args = { input: props.input }

  const { loading, error, data } = useQuery(props.queryType,
    {
      variables: query_args,
    });
  if (loading) return "Loading";
  if (error) return "Error: " + error.message;

  console.log(data)
  if (data.listings.length === 0) {
    return (
      <p>You don't have any listings</p>
    )
  }

  if (data) {
    let listing_images = data.listings.map(listing_data => {

      return (
        <Link to={`/listings/${listing_data._id}`} key={listing_data._id}>
          <ProfilePreview src={listing_data.clothing_item.photos[0]}
            alt={listing_data.clothing_item.item_name} />
        </Link>
      )
    }
    );
    return (
      <>
        {listing_images}
        <ProfileLink to='/my_listings'><SeeAll src="images/seeall_icon.png" alt="See All" /></ProfileLink>
      </>
    );
  }
};

// Custom to test!
const SavedListingsContent = (props) => {

  const app = useRealmApp();
  const user_id = app.currentUser.customData._id;
  const query_args = { input: props.input }

  const { loading, error, data } = useQuery(props.queryType,
    {
      variables: query_args,
    });
  if (loading) return "Loading";
  if (error) return "Error: " + error.message;

  console.log(data)
  if (data.UserLikedListingsQuery.length === 0) {
    return (
      <p>You don't have any listings</p>
    )
  }

  if (data) {
    let listing_images = data.UserLikedListingsQuery.map(listing_data => {

      return (
        <Link to={`/listings/${listing_data._id}`} key={listing_data._id}>
          <ProfilePreview src={listing_data.clothing_item.photos[0]}
            alt={listing_data.clothing_item.item_name} />
        </Link>
      )
    }
    );
    return (
      <>
        {listing_images}
        <ProfileLink to='/saved_listings'><SeeAll src="images/seeall_icon.png" alt="See All" /></ProfileLink>
      </>
    );
  }
};

const ProfileHeading = styled('h2')({
  color: '#153b2c',
});

const ProfileLink = styled(Link)({
  flexShrink: '0',
  textDecoration: 'none',
  width: '100px',
});

const ProfilePreview = styled('img')({
  maxHeight: '140px',
  borderRadius: '5px',
  objectFit: 'cover',
});

const SeeAll = styled(ProfilePreview)({
  maxHeight: '90px',
  paddingTop: '25px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const UserInfo = styled('h2')({
  marginTop: '5px',
  marginBottom: '5px',
});
