// React
import React from 'react';
// React Router
import { Link } from "react-router-dom";
// Shuffle Local
import { useRealmApp } from '../RealmApp';
import { Heading } from '../components/Feed';
import { BackChevron } from '../components/Utils';
// Material UI
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
// Feather
import { ChevronRight } from 'react-feather';

export default function ProfileSettings() {
  const app = useRealmApp();
  const handleLogout = () => { app.logOut(); }; 

  //let update_stale_data = async function() { await app.currentUser. refreshCustomData(); };
  //update_stale_data();

  return (
    <Container disableGutters
      sx={{
        width: '100%',
      }}>
      <BackChevron href="/profile"/>
      <Heading>Settings</Heading>
      <ProfileOption text="My Listings" link="/my_listings"/>
      <ProfileOption text="Help" link="/help"/>
      <LogOutButton logoutFunction={handleLogout}/>
    </Container>
  );
}

const ProfileOption = (props) => (
  <Link to={props.link} style={{ textDecoration: 'none'}}>
    <Box sx={{
      padding: '10px 10px 10px 10px',
    }}>
      <ProfileOptionText>{props.text}</ProfileOptionText>
      <ProfileRightArrow/>
    </Box>
  </Link>
);

const LogOutButton = (props) => (
  <Box sx={{
    padding: '10px 10px 10px 10px',
    cursor: 'pointer',
  }} onClick={props.logoutFunction}>
    <LogOutText onClick={props.logoutFunction}>Log Out</LogOutText>
  </Box>
);

const ProfileOptionText = styled('h2')({
  display: 'inline',
  color: '#153b2c',
});

const LogOutText = styled('h2')({
  display: 'inline',
  color: '#82c6ab',
});

const ProfileRightArrow = styled(ChevronRight)({
  color: '#153b2c',
  marginRight: 'auto',
  float: 'right',
  height: '2em',
  top: '.125em',
  position: 'relative',
});
