import React from "react";
import { useRealmApp } from "../RealmApp";

// Material UI
import { styled } from "@mui/system";
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";

import validator from "validator";
// NonEmpty Array? What was this used for? 
//import { isNonEmptyArray } from "@apollo/client/utilities";

import Login from "../components/Login";
import SignUp from "../components/SignUp";


export default function LoginScreen(props) {
  /*
    Props:
    - setUserProfile
  */
  const app = useRealmApp();
  const [mode, setMode] = React.useState("login");
  const togglemode = () => {
    setMode((oldMode) => (oldMode === "login" ? "register" : "login"))
  };
  
  if (mode === "login") {
    return (
      <Login toggleCreateAccount={togglemode}/>
    );
  }
  else {
    return (
      <SignUp toggleLogin={togglemode} setUserProfile={props.setUserProfile}/>
    );
  }
}


const ToggleContainer = styled(Box)({
  marginTop: 8,
  fontSize: 12,
  display: 'flex',
  justifyContent: 'center',
});

const Container = styled(Box)({
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: "#fbfbfb",
});

const ToggleLink = styled(Button)({
  background: "none",
  textTransform: "none",
});

const LoginHeading = styled(Box)({
  margin: 0,
  fontSize: 32,
});

const LoginFormRow = styled(Box)({
  marginBottom: 16,
});

const ToggleText = styled(Box)({
  lineHeight: 18,
});
